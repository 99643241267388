
.meta-table {
  td, th {
    vertical-align: middle;
  }
  .form-group {
    margin-bottom: 0!important;
  }
}
.copy-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  i {
    width: 16px;
    color: #5369f8;
  }
}
